import * as React from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Page from '../components/Page/Page'
import HQTheme from '../theme/theme'
import { Image } from 'cloudinary-react'

interface AboutPageProps {
  classes?: any
  location?: any
  data: HQ.AboutPage
}

class PressPage extends React.Component<AboutPageProps, {}> {
  render() {
    const {
      data: {
        datoCmsAboutPage: {
          title,
          subtitle,
          descriptionNode: {
            childMarkdownRemark: { html: descriptionHTML },
          },
          footerTextNode: {
            childMarkdownRemark: { html: footerHTML },
          },
          backgroundNode: {
            childMarkdownRemark: { html: backgroundHTML },
          },
          textNode: {
            childMarkdownRemark: { html: MainHtml },
          },
          technologyStackNode: {
            childMarkdownRemark: { html: technologyHTML },
          },
          taglineNode: {
            childMarkdownRemark: { html: taglineHTML },
          },
          button: [{ link, text: buttonText }],
        },
      },
      classes: c,
      location,
    } = this.props
    return (
      <Layout location={location}>
        <Page title={title} subtitle={subtitle}>
          {/* <CloudinaryContext cloudName="hq-app" secure> */}
          <div className={classNames(c.contentGrid, '-reverse')}>
            <div className={c.contentGridHalf}>
              <div className={classNames(c.contentGridBlock, '-alternativeBg')}>
                <div className={c.contentGridBlockText} dangerouslySetInnerHTML={{ __html: MainHtml }}></div>
              </div>
            </div>
            <div className={classNames(c.contentGridHalf, '-stretch')}>
              <div className={classNames(c.contentGridBlock, '-noBg')}>
                <div className={c.contentGridBlockGraphic}>
                  <Image cloudName="hq-app" secure publicId="logo.png" crop="scale" dpr="2.0" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={classNames(c.contentBlock, '-hidden')}>
            <h1 className={c.blockTitle}>Team</h1>
            <div className={c.flexLayout}>
              {teamMembers.map(member => {
                return (
                  <div key={member.name} className={c.teamMember}>
                    <div className={c.teamMemberGraphic}>
                      <img src="/assets/kenny.jpg" />
                    </div>
                    <div className={c.teamMemberInfo}>
                      <span>Kenny Vandenberghe</span>
                      <span>Frontend-developer</span>
                      <div>Linkedin</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}

          <div className={c.contentGrid}>
            <div className={c.contentGridHalf}>
              <div className={c.contentGridBlock}>
                <h3 className={c.contentGridBlockTitle}>Tagline</h3>
                <div className={c.contentGridBlockText} dangerouslySetInnerHTML={{ __html: taglineHTML }}></div>
              </div>
              <div className={c.contentGridBlock}>
                <h3 className={c.contentGridBlockTitle}>Background</h3>
                <div className={c.contentGridBlockText} dangerouslySetInnerHTML={{ __html: backgroundHTML }}></div>
              </div>
              <div className={c.contentGridBlock}>
                <h3 className={c.contentGridBlockTitle}>Technology stack</h3>
                <div className={c.contentGridBlockText} dangerouslySetInnerHTML={{ __html: technologyHTML }}></div>
              </div>
            </div>
            <div className={c.contentGridHalf}>
              <div className={classNames(c.contentGridBlock, '-stretch')}>
                <h3 className={c.contentGridBlockTitle}>Description</h3>
                <div className={c.contentGridBlockText} dangerouslySetInnerHTML={{ __html: descriptionHTML }}></div>
              </div>
            </div>
          </div>
          <div className={c.callToSignUp}>
            <span dangerouslySetInnerHTML={{ __html: footerHTML }}></span>
            <span>
              <a href={link}>{buttonText}</a>
            </span>
          </div>
          {/* </CloudinaryContext> */}
        </Page>
      </Layout>
    )
  }
}

export const query = graphql`
  query AboutPage {
    datoCmsAboutPage {
      title
      subtitle
      textNode {
        childMarkdownRemark {
          html
        }
      }
      technologyStackNode {
        childMarkdownRemark {
          html
        }
      }
      backgroundNode {
        childMarkdownRemark {
          html
        }
      }
      taglineNode {
        childMarkdownRemark {
          html
        }
      }
      footerTextNode {
        childMarkdownRemark {
          html
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      button {
        link
        text
      }
    }
  }
`

const styles = {
  contentBlock: {
    padding: '24px',
    margin: '48px 0 0 0',
    width: '50%',
    '&.-hidden': {
      display: 'none',
    },
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.75rem',
    color: '#999999',
    fontWeight: '300',
    textAlign: 'justify',
    '&:first-of-type': {
      marginTop: '0',
    },
    '&:last-of-type': {
      marginBottom: '0',
    },
  },
  contentGrid: {
    display: 'flex',
    margin: '48px auto 48px auto',
    '@media (max-width: 1200px)': {
      margin: '24px auto 24px auto',
      flexDirection: 'column',
      '&.-reverse': {
        flexDirection: 'column-reverse',
      },
    },
  },
  contentGridHalf: {
    flex: '1',
    '&:first-of-type': {
      marginRight: '24px',
      '@media (max-width: 1200px)': {
        marginRight: '0',
      },
    },
    '&.-stretch': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  contentGridBlock: {
    backgroundColor: '#FFFFFF',
    padding: '24px',
    marginBottom: '16px',
    boxShadow: '0 0px 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24)',
    borderRadius: '4px',
    '& > :first-child': {
      marginTop: '0',
    },
    '& > :last-child': {
      marginBottom: '0',
    },
    '&.-alternativeBg': {
      backgroundColor: '#F2F2F2',
      boxShadow: 'none',
      borderLeft: `4px solid ${HQTheme.palette.primary.main}`,
    },
    '&.-noBg': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&.-stretch': {
      height: 'calc(100% - 64px)',
    },
  },
  contentGridBlockTitle: {
    fontSize: '1.2rem',
    color: HQTheme.palette.primary.main,
    fontWeight: '500',
    marginBottom: '16px'
  },
  contentGridBlockText: {
    fontSize: '1rem',
    color: '#999999',
    fontWeight: '300',
    lineHeight: '1.6',
    '& > a': {
      margin: '0',
      color: HQTheme.palette.text.primaryContrast,
      fontWeight: '300',
    },
    '& > ul': {
      marginLeft: '16px'
    },
  },
  contentGridBlockGraphic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '50%',
      height: '50%',
      boxShadow: '0 0px 2px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.24)',
      borderRadius: '20%',
      '@media (max-width: 1200px)': {
        width: '64px',
        height: '64px',
        borderRadius: '16px',
      },
    },
  },
  callToSignUp: {
    textAlign: 'center',
    color: '#666666',
    margin: '0 auto 48px auto',
    '& > span:first-of-type': {
      fontWeight: '300',
      fontSize: '1.4rem',
      lineHeight: '1.5',
    },
    '& > span:last-of-type': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '32px 0',
      '& > a': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '56px',
        padding: '0 24px',
        color: '#FFFFFF',
        fontSize: '1rem',
        fontWeight: '500',
        backgroundColor: HQTheme.palette.primary.main,
        borderRadius: '12px',
        cursor: 'pointer',
        textDecoration: 'none',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        '&:hover': {
          backgroundColor: HQTheme.palette.secondary.main,
        },
      },
    },
  },
  halfContentBlock: {
    width: 'calc(50% - 12px)',
    float: 'left',
    backgroundColor: '#FFFFFF',
    padding: '0 24px',
    '&.margin-right': {
      marginRight: '12px',
    },
    '&.margin-left': {
      marginLeft: '12px',
    },
    '@media (max-width: 1024px)': {
      '&.margin-right': {
        marginRight: '0',
      },
      '&.margin-left': {
        marginLeft: '0',
      },
      width: '100%',
    },
  },
  halfBlockTitle: {
    fontSize: '1.5rem',
    fontWeight: '400',
  },
  flexLayout: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  blockTitle: {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '300',
  },
  teamMember: {
    display: 'inline-block',
    textAlign: 'center',
    width: '25%',
    marginBottom: '32px',
    '&:last-of-type': {
      marginBottom: '0',
    },
    '@media (max-width: 1300px)': {
      width: 'calc(100% / 3)',
    },
    '@media (max-width: 767px)': {
      width: 'calc(100% / 2)',
    },
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  teamMemberGraphic: {
    '& > img': {
      width: '250px',
      height: '250px',
      borderRadius: '50%',
      '@media (max-width: 1024px)': {
        width: '200px',
        height: '200px',
      },
    },
  },
  teamMemberInfo: {
    '& > span:first-of-type': {
      fontWeight: '500',
      display: 'block',
    },
    '& > span:last-of-type': {
      fontWeight: '300',
      display: 'block',
    },
  },
}

// const teamMembers = [
//   {
//     name: 'Kenny Vandenberghe1',
//     jobDesc: 'frontend-developer',
//   },
//   {
//     name: 'Kenny Vandenberghe2',
//     jobDesc: 'frontend-developer',
//   },
//   {
//     name: 'Kenny Vandenberghe3',
//     jobDesc: 'frontend-developer',
//   },
//   {
//     name: 'Kenny Vandenberghe4',
//     jobDesc: 'frontend-developer',
//   },
//   {
//     name: 'Kenny Vandenberghe5',
//     jobDesc: 'frontend-developer',
//   },
//   {
//     name: 'Kenny Vandenberghe6',
//     jobDesc: 'frontend-developer',
//   },
//   {
//     name: 'Kenny Vandenberghe7',
//     jobDesc: 'frontend-developer',
//   },
// ]

export default injectSheet(styles)(PressPage)
